<template>
  <div>
    <!-- <h1>Testing</h1> -->
    <!-- :items-per-page="itemsPerPage" -->
    <!-- @current-items="getFiltered" -->
    <!-- style="margin: 25px 25px;" -->
    <v-img
      height="100%"
      :src="flatPic"
      v-if="!soldUnitsFiltered.length"
    ></v-img>
    <v-data-table
      v-if="soldUnitsFiltered.length"
      :headers="headers"
      :items="soldUnitsFiltered"
      :search="search"
      dense
      class="elevation-0"
      multi-sort
    >
      <!-- :color="getColor(item.sold)" -->
      <template v-slot:item.unit="{ item }">
        <v-chip :id="item._id" color="green" small dark @click="chooseUnit">
          {{ item.unit }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import axios from "axios";
let url = process.env.VUE_APP_BASEURL;
export default {
  props: {
    developmentNames: String,
    search: String
  },

  data() {
    return {
      originalData: [],
      soldUnits: [],
      soldUnitsFiltered: [],
      unsoldUnits: [],
      // search: "",
      flatPic: require("../assets/flat.jpg"),
      headers: [
        {
          text: "Development",
          align: "start",
          sortable: true,
          value: "development",
          width: 60
        },
        {
          text: "Unit",
          align: "start",
          sortable: true,
          value: "unit",
          width: 20
        },
        {
          text: "BasePrice",
          align: "start",
          sortable: true,
          value: "basePrice",
          width: 60
        },
        {
          text: "ContractPrice",
          align: "start",
          sortable: true,
          value: "contractPrice",
          width: 60
        },
        {
          text: "Transfer Date",
          align: "start",
          sortable: true,
          value: "transferDate",
          width: 60
        },
        {
          text: "Last Name",
          align: "start",
          sortable: true,
          value: "buyersDirectors[0].lastName",
          width: 60
        },
        {
          text: "First Name",
          align: "start",
          sortable: true,
          value: "buyersDirectors[0].firstName",
          width: 60
        },
        {
          text: "email",
          align: "start",
          sortable: true,
          value: "buyersDirectors[0].email",
          width: 50
        }
        // {
        //   text: "Sold",
        //   align: "start",
        //   sortable: false,
        //   value: "sold",
        //   width: 60,
        // },
      ]
    };
  },
  async beforeMount() {
    console.log(this.developmentNames);
    let data = {
      developments: this.developmentNames
    };
    await axios({
      method: "post",
      url: `${url}/getSoldUnits`,
      data: data
    })
      .then(
        response => {
          console.log(response.data);
          this.originalData = response.data;
          this.soldUnits = this.originalData.filter(el => {
            return el.sold === true;
          });
          this.soldUnits.forEach(el => {
            if (el.transferDate !== "") {
              el.transferDate = dayjs(el.transferDate).format("YYYY-MM-DD");
            }
          });
          console.log(this.originalData.length);
          console.log(this.soldUnits.length);

          //   let filter = [];
          let preFilter = this.developmentNames.split(" & ");
          console.log(preFilter.length);
          this.soldUnitsFiltered = [];
          if (preFilter.length === 1) {
            this.soldUnitsFiltered = this.soldUnits.filter(el => {
              return el.development === preFilter[0];
            });
          } else if (preFilter.length === 2) {
            this.soldUnitsFiltered = this.soldUnits.filter(el => {
              return (
                el.development === preFilter[0] ||
                el.development === preFilter[1]
              );
            });
          } else if (preFilter.length === 3) {
            this.soldUnitsFiltered = this.soldUnits.filter(el => {
              return (
                el.development === preFilter[0] ||
                el.development === preFilter[1] ||
                el.development === preFilter[2]
              );
            });
          }
          console.log(this.soldUnitsFiltered);
        },
        error => {
          console.log(error);
        }
      )
      .catch(e => {
        console.log(e);
      });
  },
  methods: {
    chooseUnit(event) {
      console.log(event.currentTarget.id);
      this.$router.push(`/salesMongo/${event.currentTarget.id}`);
    }
  }
};
</script>
