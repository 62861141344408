<template>
  <div class="about">
    <v-row style="margin: 0px 10px;">
      <!-- <v-col class="mb-4" offset="0" cols="12" sm="12" md="12">
        <v-btn @click="createCollection">Create for Mongo</v-btn>
      </v-col>
    </v-row>
    <div style="display: flex; flex-direction: column; margin: 0px 10px"> -->
      <div style="display: flex; height: 40vh; width: 100%">
        <div
          style="
            width: 25%;
            display: flex;
            flex-direction: column;
            border: 1px solid black;
          "
        >
          <div
            style="display: flex; justify-content: center; margin: 10px 25px"
          >
            <h3>{{ developmentsString }} Sales Summary</h3>
          </div>
          <div
            style="display: flex; justify-content: space-between; margin: 2px 5px"
          >
            <h4
              style="width: 40%; border-radius: 5%; background: lightgrey"
            ></h4>
            <h4 style="background: lightgrey; width: 20%">Unit</h4>
            <h4 style="background: lightgrey; width: 0%"></h4>
            <h4 style="background: lightgrey; width: 40%">Value</h4>
          </div>
          <div
            style="display: flex; justify-content: space-between; margin: 2px 5px"
            v-for="(item, index) in salesStats"
            :key="index"
          >
            <h4
              style="
                border: 1px solid black;
                width: 40%;
                border-radius: 5%;
                background: yellow;
              "
            >
              {{ item.type }}
            </h4>
            <h4 style="background: lightgrey; width: 10%">{{ item.result }}</h4>
            <h4 style="background: lightgrey; width: 10%">
              {{ item.percentage }}
            </h4>
            <h4 style="background: lightgrey; width: 40%">{{ item.value }}</h4>
          </div>
        </div>
        <div style="width: 45%; border: 1px solid black">
          <div
            style="display: flex; justify-content: center; margin: 10px 25px"
          >
            <h3>Dashboard</h3>
          </div>
          <div
            style="display: flex; justify-content: space-around; margin-top: 10px; width: 100%;"
          >
            <iframe
              style="
                background: #ffffff;
                border: none;
                border-radius: 2px;
                box-shadow: 0 2px 10px 0 rgba(70, 76, 79, 0.2);
              "
              width="50%"
              height="100%"
              src="https://charts.mongodb.com/charts-project-0-qwyaz/embed/charts?id=e9418ec1-d437-43c9-836e-8496a58ca341&maxDataAge=3600&theme=light&autoRefresh=true"
            ></iframe>
            <iframe
              style="
                background: #ffffff;
                border: none;
                border-radius: 2px;
                box-shadow: 0 2px 10px 0 rgba(70, 76, 79, 0.2);
              "
              width="50%"
              height="100%"
              src="https://charts.mongodb.com/charts-project-0-qwyaz/embed/charts?id=9693e7ba-954a-4d58-81eb-52a1e2d7b030&maxDataAge=3600&theme=light&autoRefresh=true"
            ></iframe>
          </div>
        </div>
        <div
          style="
            width: 30%;
            border: 1px solid black;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <v-btn
            style="margin: 5px 0px; width: 45%"
            dark
            color="grey"
            @click="sale"
            >New Sale</v-btn
          >
          <v-btn
            style="margin: 5px 0px; width: 45%"
            dark
            color="grey"
            @click="newDev"
            >Reports</v-btn
          >
          <v-btn
            style="margin: 5px 0px; width: 45%"
            dark
            color="grey"
            @click="newDev"
            >Client Handover</v-btn
          >
          <v-text-field
            :disabled="!showTable"
            v-model="search"
            style="width: 90%; margin: 3px 5px"
            outlined
            placeholder="Search here by Client info, Agent, Development of type Unit Number"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <div
            style="display: flex; justify-content: space-evenly; margin-bottom: 25px; width: 100%;"
          >
            <v-btn
              :color="item.color"
              :id="item.name"
              dark
              v-for="(item, index) in developments"
              :key="index"
              style="margin: 0px 3px; font-size: 80%; width: 30%;"
              @click="chooseDevelopment"
              >{{ item.name }}</v-btn
            >
          </div>
        </div>
      </div>
      <div
        style="height: 45vh; border: 1px solid black; width: 100%"
        id="container"
      >
        <v-img height="100%" :src="flatPic" v-if="!showTable"></v-img>
        <MongoSoldUnits
          :developmentNames="developmentsString"
          :search="search"
          v-if="showTable"
          :key="componentKey"
        />
        <!-- <span v-else>Show Table for {{developmentsString}}</span> -->
      </div>
      <!-- </div> -->
    </v-row>
    <v-snackbar v-model="snackbar" top shaped color="amber">
      {{ snackbarMessage }}
      <v-btn color="pink" text timeout="2500" @click="snackbar = false"
        >Close</v-btn
      >
    </v-snackbar>
  </div>
</template>

<script>
// import * as dayjs from "dayjs";
import axios from "axios";
import MongoSoldUnits from "../components/MongoSoldUnits.vue";
let url = process.env.VUE_APP_BASEURL;
export default {
  name: "SalesMongo",
  components: {
    MongoSoldUnits
    // PlanType1
  },
  metaInfo: {
    title: "About us",
    titleTemplate: "CPC - %s",
    meta: [
      {
        name: `description`,
        content: `About CPC here.`
      }
    ],
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  data() {
    return {
      salesStats: [
        {
          type: "Available",
          result: 66,
          percentage: "66%",
          value: "R66 000 000"
        },
        {
          type: "Pending",
          result: 32,
          percentage: "66%",
          value: "R66 000 000"
        },
        { type: "Sold", result: 11, percentage: "66%", value: "R66 000 000" },
        {
          type: "Bond Approved",
          result: 17,
          percentage: "66%",
          value: "R66 000 000"
        },
        {
          type: "Registered",
          result: 7,
          percentage: "66%",
          value: "R66 000 000"
        }
      ],
      developments: [
        { name: "Endulini", selected: false, color: "grey" },
        { name: "Heron Fields", selected: false, color: "grey" },
        { name: "Heron View", selected: false, color: "grey" }
      ],
      developmentsChosen: [],
      developmentsString: "Global",
      showTable: false,
      componentKey: 0,
      search: "",
      flatPic: require("../assets/flat.jpg"),
      snackbar: false,
      snackbarMessage: "",
      paths: []
    };
  },
  mounted() {},
  methods: {
    scrollToBottom() {
      const container = this.$el.querySelector("#container");
      container.scrollTop = container.scrollHeight;
    },
    async createCollection() {
      await axios({
        method: "post",
        url: `${url}/getInfoForMongo`
      })
        .then(
          response => {
            //  console.log(response.data)
            response.data.forEach(el => {
              console.log(el.unit);
            });
          },
          error => {
            console.log(error);
          }
        )
        .catch(e => {
          console.log(e);
        });
    },
    chooseDevelopment(event) {
      console.log(event.currentTarget.id);
      this.developmentsString = "";
      this.developmentsChosen = [];
      this.developments.forEach(el => {
        if (el.name === event.currentTarget.id) {
          el.selected = !el.selected;
          if (el.selected === false) {
            el.color = "grey";
          } else {
            el.color = "red";
            this.developmentsChosen.push(el.name);
          }
        }
      });
      let tableChosen = this.developments.filter(el => {
        return el.selected === true;
      });
      this.showTable = false;
      if (tableChosen.length) {
        let str = "";
        if (tableChosen.length === 1) {
          str = tableChosen[0].name;
        } else {
          tableChosen.forEach((el, index) => {
            if (index === 0) {
              str = el.name;
            } else {
              str = `${str} & ${el.name}`;
            }
          });
        }
        this.developmentsString = str;
        this.componentKey += 1;
        this.showTable = true;
        //  window.scrollToBottom()
        //  this.scrollToBottom()
        document.body.scrollTop = document.body.scrollHeight;
      } else {
        this.showTable = false;
        this.developmentsString = "Global";
      }
    },
    sale() {
      this.$router.push("/salesMongo/none");
    },
    newDev() {
      this.snackbarMessage = "For Future Development!!!";
      this.snackbar = true;
    }
  }
};
</script>

<style scoped></style>
